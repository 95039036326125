import React from 'react';
import {Form} from "react-bootstrap";
import emailjs from "emailjs-com";
import "react-toastify/dist/ReactToastify.css";
import "../../src/index.css";
import Footer from "./Footer";
import { toast } from 'react-toastify';



toast.configure()
function Contact() {
  
    function handleEmailsend(e) {
         e.preventDefault();

     emailjs.sendForm('service_knc0gfy', 'template_wx5l3iy', e.target, 'user_PQorTcbEol42gP6jhIlBd')
       .then((result) => {
           console.log(result.text);
       }, (error) => {
           console.log(error.text);
       });
       e.target.reset()
   }

   const notify = () => {
     toast.info("Your message has been sent.", {position: toast.POSITION.BOTTOM_RIGHT, autoClose: false})
   }


    return (
      <div>
        <div className="contactForm box">
          <div className="formHeadtxt">
            <h1>Contact me</h1>
          </div>
          <Form onSubmit={handleEmailsend}>
            <Form.Label>Subject</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter the subject of your message"
              name="subject"
            />
            <br />
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your name"
              name="name"
            />
            <br />
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              name="email"
            />
            <br />
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              rows={7}
              placeholder="Enter your message"
              name="message"
            />
            <br />
            <button
              onClick={notify}
              className="formbtn"
              data-tooltip="Pressing Submit will send your message"
              type="submit"
              value="Submit"
            >
              Submit
            </button>
          </Form>
          <br />
        </div>
        <Footer />
      </div>
    );
}
export default Contact;