import React from 'react'
import Footer from "./Footer"
import "../../src/index.css";

export default function Services() {

        return (
          <div>
            <div className="piePic"></div>
            <div className="services">
              <div className="servPara">
                <h2 className="servTitle">Services</h2>
                <br />
                <div className="servRange">My range of services is especially aimed at private individuals
                and freelancers.</div>
                <br />
                <br />
                <div className="servTwoPara">
                  <div className="leftPara boxServ">
                    <h5><strong>Individuals</strong></h5>
                    <ul>
                      <li> Verification of tax return filing obligation </li>
                      <li> Preparation of income tax returns for: </li>
                      <li className="indent">Income from employment</li>
                      <li className="indent">
                        Income from renting and leasing
                      </li>
                      <li className="indent">Pension income</li>
                      <li className="indent">Income from capital assets etc</li>

                      <li>Verification of tax assessment notices</li>
                      <li>
                        Verification of advance payment notices and reductions
                        of advance payments
                      </li>
                      <li>
                        Advice on special tax issues such as the purchase and
                        sale of real estate
                      </li>
                    </ul>
                  </div>
                  <br />
                  <div className="rightPara boxServ">
                    <h5><strong>Freelancer and Corporate Tax</strong></h5>
                    <ul>
                      <li>Tax registration</li>
                      <li>Financial accounting</li>
                      <li>Advance VAT returns</li>
                      <li>
                        Income surplus accounting
                        (“Einnahmenüberschussrechnung”)
                      </li>
                      <li>Annual financial statements</li>
                      <li>Preparation and submission of annual tax returns</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        );
}