import React from 'react'
import Footer from "./Footer"


export default function Impressum() {
    return (
      <div>
        <div className="impresD">
          <h1 className="impressT">Impressum</h1>
          <br />
          <div className="impress">
            Steuerberaterin Johanna Kolbe
            <br />
            <br /> Kirchstr. 23 <br />
            
            10557 Berlin <br />
            <br />
            E-Mail: kontakt@jk-stb.de
            <br />
            <br /> Steuernummer: 34/564/00707
            <br />
            <br /><strong> Zuständige Aufsichtsbehörde</strong> <br />
            <br />
            Steuerberaterkammer Berlin
            <br />
            <br /> <strong>Gesetzliche Berufsbezeichnung</strong> <br />
            <br />
            Steuerberater <br />
            <br />
            Die gesetzliche Berufsbezeichnung Steuerberater wurde in der
            Bundesrepublik Deutschland (Bundesland: Berlin) verliehen.
            <br />
            <br /> Der Berufsstand der Steuerberater unterliegt im Wesentlichen
            den nachstehenden berufsrechtlichen Regelungen, welche bei der Steuerberaterkammer Berlin
            (<a href="https://stbk-berlin.de">stbk-berlin.de</a>) eingesehen werden können:
          <br />
            <br />
            <ul>
              <li>Steuerberatungsgesetz (StBerG)</li>
              <li>
                Durchführungsverordnungen zum Steuerberatungsgesetz (DVStB)
              </li>
              <li>Berufsordnung (BOStB)</li>
              <li>Steuerberatervergütungsverordnung (StBVV)</li>
            </ul>
          </div>
        </div>
        <Footer />
      </div>
    );
}
