import React from 'react';
import { useState } from "react";
import { Navbar } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import {Link} from "react-router-dom";



 export default function Component() {
   
  const [navb,setNavig] = useState(false);
      
  const changeBackground = () => {
    if(window.scrollY >= 200){
      setNavig(true)
    }
    else{
      setNavig(false)
    }
  }

  window.addEventListener("scroll", changeBackground);

        return (
          <div className={navb ? "navb active" : "navb"}>
            <Navbar collapseOnSelect expand="lg"  variant="light">
              <Navbar.Brand
                className="navLogo"
                as={Link}
                to="/"
              >
                <img src="Ln1.png" alt="JK stb logo" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                  <Nav.Link
                    type="button"
                    data-bs-toggle="collapse"
                    href="linkToAbout"
                    data-bs-target="#collapselinkToAbout"
                    aria-expanded="false"
                    aria-controls="collapse"
                    as={Link}
                    to="/About"
                  >
                    About
                  </Nav.Link>

                  <Nav.Link
                    type="button"
                    data-bs-toggle="collapse"
                    href="linkToServices"
                    data-bs-target="#collapselinkToServices"
                    aria-expanded="false"
                    aria-controls="collapse"
                    as={Link}
                    to="/Services"
                  >
                    Services
                  </Nav.Link>
                </Nav>
                <Nav>
                  <Nav.Link
                    type="button"
                    data-bs-toggle="collapse"
                    href="linkToContact"
                    data-bs-target="#collapselinkToContact"
                    aria-expanded="false"
                    aria-controls="collapse"
                    as={Link}
                    to="/Contact"
                  >
                    Contact
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        );
}