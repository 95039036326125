import React from 'react'
import "../../src/index.css";
import Footer from './Footer';


function About() {
        return (
          <div>
            <div className="aboutall">
              <img className="aboutPic" src="ab_3.jpg" alt="Johanna Kolbe" />

              <div className="aboutTxt boxAbout">
                <h2 className="aboutTitle nameTitle">Johanna Kolbe</h2>
                <br />
                <h2 className="aboutTitle">
                  Freelance Tax Advisor for German Tax Law
                </h2>
                <br />
                Through many years of experience with international clients, the
                tax questions, concerns and needs of expats, impats and/or
                non-native speakers are of particular concern to me. I advise in
                English and German. To save your time and cost, the entire
                collaboration can take place digitally. However, if desired, I
                am also happy to meet you in person.
                <br />
                <br /> After graduating from secondary school in Berlin-Pankow,
                I studied Business Administration at the Free University of
                Berlin, graduating with a Bachelor of Science degree. Afterwards
                I did several internships in Germany and abroad and then started
                the master program International Business Administration at the
                European University in Frankfurt (Oder). Here I focused on
                business taxation and accounting and finally graduated in 2014
                with a Master of Science degree. I gained further professional
                experience in tax consulting and finally passed my tax
                consultant exam in Berlin in 2019. Since November 2019, I have
                been working as an freelance tax consultant.
              </div>
              <div className="piePic"></div>
            </div>
            <Footer />
          </div>
        );
}

export default  About;