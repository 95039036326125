import React from 'react';
import Footer from "./Footer";

export default function DataI() {
    return (
      <div>
        <div>
          <div className="datenAll">
            <h2 className="datenTitel">Datenschutzerklärung</h2>
            <br />
            <strong>1. Name und Kontaktdaten des Verantwortlichen</strong>
            <br /> Diese Datenschutzerklärung informiert über die Verarbeitung
            personenbezogener Daten auf der Kanzleiwebseite von:<br />
            <br/>Verantwortlicher: Johanna Kolbe<br />
            <br /><strong>2. Umfang und Zweck der Verarbeitung personenbezogener Daten</strong>
            <br /><strong>2.1 Aufruf der Webseite</strong>
            <br/>Beim Aufruf dieser Webseite jk-stb.de werden
            durch den Internet-Browser, den der Besucher verwendet, automatisch
            Daten an den Server dieser Webseite gesendet und zeitlich begrenzt
            in einer Protokolldatei (Logfile) gespeichert. Bis zur automatischen
            Löschung werden nachstehende Daten ohne weitere Eingabe des
            Besuchers gespeichert: <ul><li> IP-Adresse des Endgeräts des Besuchers,</li><li>
            Datum und Uhrzeit des Zugriffs durch den Besucher,</li><li>  Name und URL
            der vom Besucher aufgerufenen Seite,</li> <li> Webseite, von der aus der
            Besucher auf die Kanzleiwebseite gelangt (sog. Referrer-URL),</li> <li>
            Browser und Betriebssystem des Endgeräts des Besuchers sowie der
            Name des vom Besucher verwendeten Access-Providers.</li></ul> Die Verarbeitung
            dieser personenbezogenen Daten ist gem. Art. 6 Abs. 1 Satz 1 Buchst.
            f) DSGVO gerechtfertigt. Die Kanzlei hat ein berechtigtes Interesse
            an der Datenverarbeitung zu dem Zweck, <ul><li> die Verbindung zur Webseite
            der Kanzlei zügig aufzubauen,</li> <li> eine nutzerfreundliche Anwendung der
            Webseite zu ermöglichen,</li> <li> die Sicherheit und Stabilität der Systeme
            zu erkennen und zu gewährleisten und</li> <li> die Administration der
            Webseite zu erleichtern und zu verbessern.</li></ul> Die Verarbeitung erfolgt
            ausdrücklich nicht zu dem Zweck, Erkenntnisse über die Person des
            Besuchers der Webseite zu gewinnen.<br /> <br /><strong>2.2 Kontaktformular</strong> 
            <br />Besucher können über ein Online-Kontaktformular auf der Webseite Nachrichten
            an die Kanzlei übermitteln. Um eine Antwort empfangen zu können, ist
            zumindest die Angabe einer gültigen E-Mail-Adresse erforderlich.
            Alle weiteren Angaben kann die anfragende Person freiwillig geben.
            Mit Absenden der Nachricht über das Kontaktformular willigt der
            Besucher in die Verarbeitung der übermittelten personenbezogenen
            Daten ein. Die Datenverarbeitung erfolgt ausschließlich zu dem Zweck
            der Abwicklung und Beantwortung von Anfragen über das
            Kontaktformular. Dies geschieht auf Basis der freiwillig erteilten
            Einwilligung gem. Art. 6 Abs. 1 Satz 1 Buchst. a) DSGVO. Die für die
            Benutzung des Kontaktformulars erhobenen personenbezogenen Daten
            werden automatisch gelöscht, sobald die Anfrage erledigt ist und
            keine Gründe für eine weitere Aufbewahrung gegeben sind (z. B.
            anschließende Beauftragung unserer Kanzlei). <br /><br /><strong>3. Weitergabe von Daten</strong>
            <br />Personenbezogene Daten werden an Dritte übermittelt, wenn<br /><br /> <ul> <li> nach
            Art. 6 Abs. 1 Satz 1 Buchst. a) DSGVO durch die betroffene Person
            ausdrücklich dazu eingewilligt wurde,</li> <li> die Weitergabe nach Art. 6
            Abs. 1 Satz 1 Buchst. f) DSGVO zur Geltendmachung, Ausübung oder
            Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund
            zur Annahme besteht, dass die betroffene Person ein überwiegendes
            schutzwürdiges Interesse an der Nichtweitergabe ihrer Daten hat,</li> <li>
            für die Datenübermittlung nach Art. 6 Abs. 1 Satz 1 Buchst. c) DSGVO
            eine gesetzliche Verpflichtung besteht, und/oder</li> <li> dies nach Art. 6
            Abs. 1 Satz 1 Buchst. b) DSGVO für die Erfüllung eines
            Vertragsverhältnisses mit der betroffenen Person erforderlich ist.</li></ul>
            In anderen Fällen werden personenbezogene Daten nicht an Dritte
            weitergegeben.<br /> <br /><strong>4. Ihre Rechte als betroffene Person</strong><br />Soweit Ihre
            personenbezogenen Daten anlässlich des Besuchs unserer Webseite
            verarbeitet werden, stehen Ihnen als „betroffene Person“ im Sinne
            der DSGVO folgende Rechte zu:<br /> <br /><strong>4.1 Auskunft</strong><br /> Sie können von uns
            Auskunft darüber verlangen, ob personenbezogene Daten von Ihnen bei
            uns verarbeitet werden. Kein Auskunftsrecht besteht, wenn die
            Erteilung der begehrten Informationen gegen die
            Verschwiegenheitspflicht gem. § 57 Abs. 1 StBerG verstoßen würde
            oder die Informationen aus sonstigen Gründen, insbesondere wegen
            eines überwiegenden berechtigten Interesses eines Dritten, geheim
            gehalten werden müssen. Hiervon abweichend kann eine Pflicht zur
            Erteilung der Auskunft bestehen, wenn insbesondere unter
            Berücksichtigung drohender Schäden Ihre Interessen gegenüber dem
            Geheimhaltungsinteresse überwiegen. Das Auskunftsrecht ist ferner
            ausgeschlossen, wenn die Daten nur deshalb gespeichert sind, weil
            sie aufgrund gesetzlicher oder satzungsmäßiger Aufbewahrungsfristen
            nicht gelöscht werden dürfen oder ausschließlich Zwecken der
            Datensicherung oder der Datenschutzkontrolle dienen, sofern die
            Auskunftserteilung einen unverhältnismäßig hohen Aufwand erfordern
            würde und die Verarbeitung zu anderen Zwecken durch geeignete
            technische und organisatorische Maßnahmen ausgeschlossen ist. Sofern
            in Ihrem Fall das Auskunftsrecht nicht ausgeschlossen ist und Ihre
            personenbezogenen Daten von uns verarbeitet werden, können Sie von
            uns Auskunft über folgende Informationen verlangen: <br /><ul><li> Zwecke der
            Verarbeitung,</li> <li> Kategorien der von Ihnen verarbeiteten
            personenbezogenen Daten,</li> <li> Empfänger oder Kategorien von Empfängern,
            gegenüber denen Ihre personenbezogenen Daten offen gelegt werden,
            insbesondere bei Empfängern in Drittländern,</li> <li> falls möglich die
            geplante Dauer, für die Ihre personenbezogenen Daten gespeichert
            werden oder, falls dies nicht möglich ist, die Kriterien für die
            Festlegung der Speicherdauer,</li> <li> das Bestehen eines Rechts auf
            Berichtigung oder Löschung oder Einschränkung der Verarbeitung der
            Sie betreffenden personenbezogenen Daten oder eines
            Widerspruchsrechts gegen diese Verarbeitung,</li> <li> das Bestehen eines
            Beschwerderechts bei einer Aufsichtsbehörde für den Datenschutz,</li> <li>
            sofern die personenbezogenen Daten nicht bei Ihnen als betroffene
            Person erhoben worden sind, die verfügbaren Informationen über die
            Datenherkunft,</li> <li> ggf. das Bestehen einer automatisierten
            Entscheidungsfindung einschließlich Profiling und aussagekräftige
            Informationen über die involvierte Logik sowie die Tragweite und
            angestrebten Auswirkungen automatisierter Entscheidungsfindungen,</li> <li>
            ggf. im Fall der Übermittlung an Empfänger in Drittländern, sofern
            kein Beschluss der EU-Kommission über die Angemessenheit des
            Schutzniveaus nach Art. 45 Abs. 3 DSGVO vorliegt, Informationen
            darüber, welche geeigneten Garantien gem. Art. 46 Abs. 2 DSGVO zum
            Schutze der personenbezogenen Daten vorgesehen sind.</li></ul> <br /><strong>4.2
            Berichtigung und Vervollständigung</strong> <br />Sofern Sie feststellen, dass uns
            unrichtige personenbezogene Daten von Ihnen vorliegen, können Sie
            von uns die unverzügliche Berichtigung dieser unrichtigen Daten
            verlangen. Bei unvollständigen Sie betreffenden personenbezogenen
            Daten können sie die Vervollständigung verlangen.<br /> <br /><strong>4.3 Löschung</strong> <br /> Sie
            haben ein Recht auf Löschung („Recht auf Vergessenwerden“), sofern
            die Verarbeitung nicht zur Ausübung des Rechts auf freie
            Meinungsäußerung, des Rechts auf Information oder zur Erfüllung
            einer rechtlichen Verpflichtung oder zur Wahrnehmung einer Aufgabe,
            die im öffentlichen Interesse liegt, erforderlich ist und einer der
            nachstehenden Gründe zutrifft:<br /> <ul><li> Die personenbezogenen Daten sind
            für die Zwecke, für die sie verarbeitet wurden, nicht mehr
            notwendig.</li><li> Die Rechtfertigungsgrundlage für die Verarbeitung war
            ausschließlich Ihre Einwilligung, welche Sie widerrufen haben.</li> <li> Sie
            haben Widerspruch gegen die Verarbeitung Ihrer personenbezogenen
            Daten eingelegt, die wir öffentlich gemacht haben.</li> <li> Sie haben
            Widerspruch gegen die Verarbeitung von uns nicht öffentlich
            gemachter personenbezogener Daten eingelegt und es liegen keine
            vorrangigen berechtigten Gründe für die Verarbeitung vor.</li> <li> Ihre
            personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li> <li> Die
            Löschung der personenbezogenen Daten ist zur Erfüllung einer
            gesetzlichen Verpflichtung, der wir unterliegen, erforderlich.</li></ul>.<br />Kein
            Anspruch auf Löschung besteht, wenn die Löschung im Falle
            rechtmäßiger nicht automatisierter Datenverarbeitung wegen der
            besonderen Art der Speicherung nicht oder nur mit unverhältnismäßig
            hohem Aufwand möglich und Ihr Interesse an der Löschung gering ist.
            In diesem Fall tritt an die Stelle einer Löschung die Einschränkung
            der Verarbeitung.<br /> <br /><strong>4.4 Einschränkung der Verarbeitung</strong> <br />Sie können von
            uns die Einschränkung der Verarbeitung verlangen, wenn einer der
            nachstehenden Gründe zutrifft:<br /> <ul><li> Sie bestreiten die Richtigkeit der
            personenbezogenen Daten. Die Einschränkung kann in diesem Fall für
            die Dauer verlangt werden, die es uns ermöglicht, die Richtigkeit
            der Daten zu überprüfen.</li> <li> Die Verarbeitung ist unrechtmäßig und Sie
            verlangen statt Löschung die Einschränkung der Nutzung Ihrer
            personenbezogenen Daten.</li> <li> Ihre personenbezogenen Daten werden von
            uns nicht länger für die Zwecke der Verarbeitung benötigt, die Sie
            jedoch zur Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen benötigen.</li> <li> Sie haben Widerspruch gem. Art. 21
            Abs. 1 DSGVO eingelegt. Die Einschränkung der Verarbeitung kann
            solange verlangt werden, wie noch nicht feststeht, ob unsere
            berechtigten Gründe gegenüber Ihren Gründen überwiegen.</li></ul>
            Einschränkung der Verarbeitung bedeutet, dass die personenbezogenen
            Daten nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung
            oder Verteidigung von Rechtsan¬sprüchen oder zum Schutz der Rechte
            einer anderen natürlichen oder juristischen Person oder aus Gründen
            eines wichtigen öffentlichen Interesses verarbeitet werden. Bevor
            wir die Einschränkung aufheben, haben wir die Pflicht, Sie darüber
            zu unterrichten.<br /> <br /><strong>4.5 Datenübertragbarkeit</strong> <br />Sie haben ein Recht auf
            Datenübertragbarkeit, sofern die Verarbeitung auf Ihrer Einwilligung
            (Art. 6 Abs. 1 Satz 1 Buchst. a) oder Art. 9 Abs. 2 Buchst. a)
            DSGVO) oder auf einem Vertrag beruht, dessen Vertragspartei Sie sind
            und die Verarbeitung mithilfe automatisierter Verfahren erfolgt. Das
            Recht auf Datenübertragbarkeit beinhaltet in diesem Fall folgende
            Rechte, sofern hierdurch nicht die Rechte und Freiheiten anderer
            Personen beeinträchtigt werden: Sie können von uns verlangen, die
            personenbezogenen Daten, die Sie uns bereit gestellt haben, in einem
            strukturierten, gängigen und maschinenlesbaren Format zu erhalten.
            Sie haben das Recht, diese Daten einem anderen Verantwortlichen ohne
            Behinderung unserseits zu übermitteln. Soweit technisch machbar,
            können Sie von uns verlangen, dass wir Ihre personenbezogenen Daten
            direkt an einen anderen Verantwortlichen übermitteln.<br /> <br /><strong> 4.6
            Widerspruch</strong> <br />Sofern die Verarbeitung auf Art. 6 Abs. 1 Satz 1 Buchst.
            e) DSGVO (Wahrnehmung einer Aufgabe im öffentlichen Interesse oder
            in Ausübung öffentlicher Gewalt) oder auf Art. 6 Abs. 1 Satz 1
            Buchst. f) DSGVO (berechtigtes Interesse des Verantwortlichen oder
            eines Dritten) beruht, haben Sie das Recht, aus Gründen, die sich
            aus Ihrer besonderen Situation ergeben, jederzeit gegen die
            Verarbeitung der Sie betreffenden personenbezogenen Daten
            Widerspruch einzulegen. Das gilt auch für ein auf Art. 6 Abs. 1 Satz
            1 Buchst. e) oder Buchst. f) DSGVO gestütztes Profiling. Nach
            Ausübung des Widerspruchsrechts verarbeiten wir Ihre
            personenbezogenen Daten nicht mehr, es sei denn, wir können
            zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die
            Ihre Interessen, Rechte und Freiheiten überwiegen, oder die
            Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung
            von Rechtsansprüchen.<br /> <br />Sie können jederzeit Widerspruch gegen die
            Verarbeitung der Sie betreffenden personenbezogenen Daten zu Zwecken
            der Direktwerbung einlegen. Das gilt auch für ein Profiling, das mit
            einer solchen Direktwerbung in Verbindung steht. Nach Ausübung
            dieses Widerspruchsrechts werden wir die betreffenden
            personenbezogenen Daten nicht mehr für Zwecke der Direktwerbung
            verwenden.<br /> Sie haben die Möglichkeit, den Widerspruch telefonisch,
            per E-Mail, ggf. per Telefax oder an unsere zu Beginn dieser
            Datenschutzerklärung aufgeführte Postadresse unserer Kanzlei formlos
            mitzuteilen.<br /> <br /><strong>4.7 Widerruf einer Einwilligung</strong><br />Sie haben das Recht,
            eine erteilte Einwilligung jederzeit mit Wirkung für die Zukunft zu
            widerrufen. Der Widerruf der Einwilligung kann telefonisch, per
            E-Mail, ggf. per Telefax oder an unsere Postadresse formlos
            mitgeteilt werden. Durch den Widerruf wird die Rechtmäßigkeit der
            Datenverarbeitung, die aufgrund der Einwilligung bis zum Eingang des
            Widerrufs erfolgt ist, nicht berührt. Nach Eingang des Widerrufs
            wird die Datenverarbeitung, die ausschließlich auf Ihrer
            Einwilligung beruhte, eingestellt.<br /> <br /><strong>4.8 Beschwerde</strong><br />Wenn Sie der
            Ansicht sind, dass die Verarbeitung der Sie betreffenden
            personenbezogenen Daten rechtswidrig ist, können Sie Beschwerde bei
            einer Aufsichtsbehörde für den Datenschutz einlegen, die für den Ort
            Ihres Aufenthaltes oder Arbeitsplatzes oder für den Ort des
            mutmaßlichen Verstoßes zuständig ist.<br /> <br /><strong>5. Stand und Aktualisierung
            dieser Datenschutzerklärung</strong> <br />Diese Datenschutzerklärung hat den Stand
            vom 11. Oktober 2021. Wir behalten uns vor, die Datenschutzerklärung
            zu gegebener Zeit zu aktualisieren, um den Datenschutz zu verbessern
            und/oder an geänderte Behördenpraxis oder Rechtsprechung anzupassen.<br /><br />
          </div>
        </div>
        <Footer />
      </div>
    );
}
