import React from 'react'
import { Link } from "react-router-dom";




 function Footer() {
    return (
      <div>
        <ul className="foot">
          <li>
            <Link to="/Impressum" className="imp">Impressum</Link>
          </li>
          <li>
            <Link to="/DataI" className="dat">Data protection</Link>
          </li>
          <li>
            <p>All rights reserved JK stb. 2021</p>
          </li>
        </ul>
      </div>
    );
}

export default Footer