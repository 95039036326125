import React from 'react'
import Footer from "./Footer"


export default function Homepage() {
  
        return (
          <div>
            <div>
              <h2 className="homeTitle box">
                Johanna Kolbe <br /> Freelance Tax Advisor
              </h2>
            </div>
            <Footer />
          </div>
        );
}


