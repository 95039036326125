import React from 'react';
import Navigation from './components/Navigation'
import Homepage from './components/Homepage';
import About from "./components/About";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Impressum from "./components/Impressum";
import DataI from "./components/DataI"
import {Route,Switch} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

let stylePage = {
  width: "100%",
  height: "60vh",
  backgroundImage: "url('/cover.jpg')",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",

};


export default function App() {

    return (
      <div>
        <div>
          <Navigation />
          <div style={stylePage} className="mainBody"></div>
          <div className="separator"></div>
        </div>
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              return <Homepage />;
            }}
          />
          <Route
            exact
            path="/About"
            render={() => {
              return <About />;
            }}
          />
          <Route
            exact
            path="/Services"
            render={() => {
              return <Services />;
            }}
          />
          <Route
            exact
            path="/Contact"
            render={() => {
              return <Contact />;
            }}
          />
          <Route
            exact
            path="/Impressum"
            render={() => {
              return <Impressum />;
            }}
          />
          <Route
            exact
            path="/DataI"
            render={() => {
              return <DataI />;
            }}
          />
        </Switch>
      </div>
    );
  
}

